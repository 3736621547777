.EmotionList {
  height: 16.9rem;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  gap: 0.5rem;
  overflow-y: auto;
  padding: 0.75rem;
  /* &&::-webkit-scrollbar {
    width: 0.313rem;
  }
  &&::-webkit-scrollbar-thumb {
    background: #aaadaf;
    border-radius: 0.5rem;
  }

  &&::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
  }

  &&::-webkit-scrollbar-thumb:hover {
    background: #cacdcf;
  } */
}
