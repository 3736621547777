html {
  box-sizing: border-box;
}
body {
  padding: 0px;
  background-color: #333;
  overscroll-behavior-y: none;
}
button {
  margin: 2;
}
tr {
  vertical-align: top;
}

div {
  box-sizing: border-box;
}

:root {
  --C-1f62b9: 31 98 185;
  --C-599aee: 89 154 238;
  --C-32418e: 50 65 142;
  --C-ebedf1: 235 237 241;
  --C-b4b5b8: 180 181 184;
  --C-f1f3f6: 241 243 246;
  --C-555555: 85 85 85;
  --C-d9e5f9: 217 229 249;
  --C-b8d3fe: 184 211 254;
  --C-86878b: 134 135 139;
  --C-c8cace: 200 202 206;
  --C-9d9ea2: 157 158 162;
  --C-666666: 102 102 102;
  --C-282625: 40 38 37;
  --C-f6ce65: 246 206 101;
  --C-fa114f: 250 17 79;
  --C-dfe2e8: 223 226 232;
  --C-18c0fd: 24 192 253;
  --C-9bd00d: 155 208 13;
  --C-2a3674: 42 54 116;
  --C-636465: 99 100 101;
  --C-00bf60: 0 191 96;
  --C-434343: 67 67 67;
  --C-707070: 112 112 112;
  --C-d5d7dc: 213 215 220;
  --C-459d17: 69 157 23;
  --C-70159b: 112 21 155;
  --C-118ab5: 17 138 181;
  --C-604790: 96 71 144;
  --C-38b5de: 56 181 222;
  --C-fa164f: 250 22 79;
  --C-006a66: 17 138 181;
  --C-ff9500: 0 106 102;
  --C-676767: 103 103 103;
  --C-04de71: 4 222 113;
  --C-1ac9fe: 26 201 254;
  --C-522391: 82 35 145;
  --C-787afd: 120 122 253;
  --C-eef8e7: 238 248 231;
  --C-21bf60: 33 191 96;
  --C-f8e7e7: 248 231 231;
  --C-d21111: 210 17 17;
  --C-333333: 51 51 51;
  --C-999999: 153 153 153;
  --C-222222: 34 34 34;
  --C-e9f0f8: 233 240 248;
  --C-d6e3f2: 214 227 242;
  --C-f8f9fa: 248 249 250;
  --C-36382e: 54 56 46;
  --C-565656: 86 86 86;
}
