.rc-tree-switcher.rc-tree-switcher_close,
.rc-tree-switcher.rc-tree-switcher_open {
  display: inline-block;
  padding-right: 0.75rem;
}

.rc-tree-indent-unit {
  display: inline-block;
  width: 1.5rem;
}

.rc-tree-node-content-wrapper {
  display: block;
  width: 100%;
}

.rc-tree-treenode-checkbox-checked {
  background-color: #f1f3f6;
}
