body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.full-screen {
  height: calc(var(--vh, 1vh) * 100);
  overflow-y: hidden;
}

*::-webkit-scrollbar {
  width: 0.375rem;
  height: 0.375rem;
}

*::-webkit-scrollbar-thumb {
  background: #b4b5b8;
  border-radius: 0.5rem;
}

*::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
}

*::-webkit-scrollbar-thumb:hover {
  background: #888;
}

input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -webkit-text-security: disc;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr 2fr);
}

@tailwind base;
@layer base {
  img {
    @apply inline-block;
  }
}
@tailwind components;
@tailwind utilities;
